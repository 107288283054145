<template>
  <div class="container">
    <navbar></navbar>
    <div class="content t-white px-30">
      <p class="bold fs-xxl t-center">{{ $t("myNFTCard.collectmine") }}</p>
      <!-- 卡牌列表 -->
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          :offset="100"
          @load="onLoad"
        >
          <div class="d-flex flex-wrap jc-between m-t-15">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="card_item m-b-20"
            >
              <van-image
                width="100%"
                height="210px"
                :src="require('@/assets/gif/border' + item.card_level + '.png')"
              />
              <van-image
                width="100%"
                height="210px"
                class="img p-5"
                :src="item.img_url"
              />
              <!-- time_count 已发放次数（<24 时 不可收取 =24 可以收取） -->
              <div
                class="collect"
                v-if="item.time_count == 24"
                @click="onCollect(item.p_id)"
              >
                <p class="bold fs-xl t-center">{{ $t("myNFTCard.collect") }}</p>
              </div>
              <div
                class="
                  iscollect
                  d-flex
                  flex-column
                  ai-center
                  jc-center
                  lh-2
                  t-center
                "
                v-else-if="item.time_count < 24"
              >
                <p>{{ $t("myNFTCard.countdown") }}</p>
                <div class="d-flex ai-center m-t-3">
                  <span>collection</span>
                  <van-count-down
                    :time="getValue(item.collect_time)"
                    class="t-white"
                    @finish="onfinish"
                  />
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="d-flex jc-center" v-if="list.length > 0">
        <van-button color="#074CE7" class="btn" @click="onCollect()">{{
          $t("myNFTCard.oneclick")
        }}</van-button>
      </div>
    </div>
    <van-popup v-model="isShow" overlay-class="overlay2">
      <div class="borders fs-xxl d-flex ai-center jc-center">
        <p>
          <span class="t-color5">{{ total_reward }}</span> UANG
          {{ $t("myNFTCard.charged") }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [
        // {
        //   p_id: 24,
        //   card_id: 22,
        //   time_count: 24,
        //   collect_time: "2022-04-26 10:47:00",
        //   img_url:
        //     "https://maskziptest.oss-cn-hongkong.aliyuncs.com/images/1ead97e156fc1e9718d2ac5eb2d6587a.png",
        // },
      ],
      isShow: false,
      level: 4,
      total_reward: "", //总收取数量
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    getValue(time) {
      var time1 = new Date().getTime(); //当前时间转换为时间戳
      var time2 = new Date(time.replace(/-/g, "/"));
      var time2 = time2.getTime();
      var runTime = ((time2 - time1) / 1000).toFixed(0); //开始得出时间差,.toFixed(0)保留小数点后0位## 标题
      return runTime * 1000;
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        if (this.pageNumber >= this.totalPage) {
          this.finished = true;
          this.loading = false;
        } else {
          this.pageNumber++;
          this.getList();
        }
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList() {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.dig_list, {
          page: this.pageNumber,
          page_size: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            this.loading = false;
            this.finished = false;
            this.refreshing = false;
            let arr = res.data.list;
            if (this.pageNumber == 1) {
              this.list = arr;
              this.totalPage = res.data.totalPage;
            } else {
              this.list.push(...arr);
            }
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        }).catch(err=>{
            this.loading = false;
        })
    },
    //倒计时结束
    onfinish() {
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    //收取
    onCollect(id) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      this.$axios
        .post(this.$api.one_collect, {
          p_id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast.clear();
            this.total_reward = res.data.total_reward;
            this.isShow = !this.isShow;
            setTimeout(() => {
              this.getList();
            }, 500);
          } else {
            this.$toast(res.msg);
            setTimeout(() => {
              this.getList();
            }, 500);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
/deep/ .van-grid-item__content {
  background-color: transparent;
  padding: 10px 0;
}
/deep/.van-count-down {
  font-size: 13px;
  line-height: 0;
}
.card_item {
  width: 47%;
  position: relative;
  .img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .collect {
    width: 61px;
    height: 61px;
    line-height: 61px;
    background: url("~@/assets/collect.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .iscollect {
    width: 100%;
    min-height: 42px;
    background: url("~@/assets/juxing.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.btn {
  min-width: 130px;
  height: 30px;
  border-radius: 10px;
  font-size: 12px;
}
.borders {
  width: 210px;
  height: 80px;
}
</style>